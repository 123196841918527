<template>
  <div>
    <OrderLanding
      v-if="showLanding"
      @show-section="showSection"/>

    <PrivatOrder
      v-if="showPrivat"
      @back="showLanding = true; showPrivat = false; showBedrift = false;"/>

    <BedriftOrder
      v-if="showBedrift"
      @back="showLanding = true; showPrivat = false; showBedrift = false;"/>
  </div>
</template>
<script>
import { Page, OrderLanding, PrivatOrder, BedriftOrder } from 'global-components';

export default {
  name: 'order-page',
  components: {
    OrderLanding,
    PrivatOrder,
    BedriftOrder,
  },
  extends: Page,
  data() {
    return {
      showLanding: true,
      showPrivat: false,
      showBedrift: false
    };
  },
  methods: {
    showSection(sectionName) {
      this.showLanding = false;
      if (sectionName === 'privat') {
        this.showPrivat = true;
        this.showBedrift = false;
      } else {
        this.showPrivat = false;
        this.showBedrift = true;
      }
    }
  }
};
</script>
